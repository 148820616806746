/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useState, useEffect } from "react";

// third-party modules
import {
  Fieldset,
  RichTextField,
  SearchField,
  SelectField,
  TextField
} from "@onehq/anton";

// app modules
import {
  ClientInput,
  ClientQueryFilterFields,
  FilterOperation,
  useGetClientsListLazyQuery
} from "../../../generated/graphql";
import { DEFAULT_LIMIT } from "../../../constants";
import { SelectFieldOptionType } from "../../../types";
import {
  agenciesOptionsFrom,
  clientStatusOptions,
  clientTypeOptions
} from "../../../utils";
import { ListFilterValue } from "../../../components/ListFilter/utils";

export const ClientGeneralFormOmitValues = [
  "searchAgencyId",
  "agency",
  "clientsCount",
  "projectsCount",
  "mediaFilename",
  "mediaFiletype",
  "mediaUrl",
  "phones", // not needed anymore
  "providerNumbers",
  "clientNumbers",
  "__typename",
  "listFiltersAttributes"
];

export type ClientFieldsWithSearchAgencyInput = ClientInput & {
  searchAgencyId?: {
    label: string;
    value: string;
  };
  listFiltersAttributes: ListFilterValue;
};

const ClientFormFields = () => {
  // Options
  // lazy query fetch clients (for agencies field) data on first render
  const [agenciesQuery] = useGetClientsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [defaultAgenciesOptions, setDefaultAgenciesOptions] = useState<
    Array<SelectFieldOptionType>
  >([]);

  // fetch clients on first render
  useEffect(() => {
    agenciesQuery({
      variables: {
        limit: DEFAULT_LIMIT
      }
      // TODO: remove
    }) // @ts-ignore
      .then(response => {
        setDefaultAgenciesOptions(agenciesOptionsFrom(response.data) || []);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <Fieldset>
      <TextField label="Name" name="name" required />
      {/* @ts-ignore */}
      <SelectField
        label="Client Type"
        name="clientType"
        options={clientTypeOptions}
        required
      />
      {/* @ts-ignore */}
      <SelectField
        label="Client Status"
        name="clientStatus"
        options={clientStatusOptions}
        required
      />
      {/* @ts-ignore */}
      <SearchField
        label="Agency"
        name="searchAgencyId"
        defaultOptions={defaultAgenciesOptions}
        required={false}
        loadOptions={(text: string) =>
          agenciesQuery({
            variables: {
              filters: [
                {
                  field: ClientQueryFilterFields.Name,
                  operation: FilterOperation.Like,
                  value: text
                }
              ]
            }
            // TODO: remove
            // @ts-ignore
          }).then(response => agenciesOptionsFrom(response.data) || [])
        }
      />
      {/* @ts-ignore */}
      <RichTextField label="Note" name="note" />
    </Fieldset>
  );
};

export default ClientFormFields;
