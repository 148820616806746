// TODO: remove if no longer necessary
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Fieldset,
  TextField,
  RichTextField,
  SelectField,
  NestedForm,
  SearchField
} from "@onehq/anton";
import {
  useGetClientsListLazyQuery,
  ClientQueryFilterFields,
  FilterOperation
} from "../../generated/graphql";
import {
  agenciesOptionsFrom,
  clientTypeOptions,
  clientStatusOptions
} from "../../utils/options";
import { GenericFormProps, SelectFieldOptionType } from "../../types";
import { ClientInputWithAgencyData } from "./ClientForm";
import { DiscardFormModal } from "../../contexts/DiscardFormModal";
import ClientPhonesForm from "./ClientPhonesForm";
import { DEFAULT_LIMIT } from "../../constants";
import ButtonGroup from "../Form/ButtonGroup";

interface ClientFormFieldsProps
  extends GenericFormProps<ClientInputWithAgencyData> {
  resourceToFill?: {
    type: String;
    id: String;
  };
  onSubmit: () => void;
  onDiscard?: () => void;
}

const ClientFormFields = (props: ClientFormFieldsProps) => {
  // client values
  const values = props.values;
  // edition or creation
  const isEdit = !!(values && values.id);
  // Options
  // lazy query fetch clients (for agencies field) data on first render
  const [agenciesQuery] = useGetClientsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [defaultAgenciesOptions, setDefaultAgenciesOptions] = useState<
    Array<SelectFieldOptionType>
  >([]);

  // fetch clients on first render
  useEffect(() => {
    agenciesQuery({
      variables: {
        limit: DEFAULT_LIMIT
      }
      // TODO: remove
    }) // @ts-ignore
      .then(response => {
        setDefaultAgenciesOptions(agenciesOptionsFrom(response.data) || []);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Fieldset>
        <TextField label="Name" name="name" required />
        {/* @ts-ignore */}
        <SelectField
          label="Client Type"
          name="clientType"
          options={clientTypeOptions}
          required
        />
        {/* @ts-ignore */}
        <SelectField
          label="Client Status"
          name="clientStatus"
          options={clientStatusOptions}
          required
        />
        {/* @ts-ignore */}
        <SearchField
          label="Agency"
          name="agencyId"
          defaultOptions={defaultAgenciesOptions}
          required={false}
          loadOptions={(text: string) =>
            agenciesQuery({
              variables: {
                filters: [
                  {
                    field: ClientQueryFilterFields.Name,
                    operation: FilterOperation.Like,
                    value: text
                  }
                ]
              }
              // TODO: remove
              // @ts-ignore
            }).then(response => agenciesOptionsFrom(response.data) || [])
          }
        />
        {/* @ts-ignore */}
        <RichTextField label="Note" name="note" />
        <Fieldset>
          <NestedForm
            legend={"Phones"}
            name="phones"
            component={ClientPhonesForm}
            condensed
          />
        </Fieldset>
      </Fieldset>
      {!isEdit && (
        <ButtonGroup>
          <DiscardFormModal resource="Client" onDiscard={props.onDiscard}>
            <Button variant={"secondary"}> Discard </Button>
          </DiscardFormModal>
          <Button onClick={props.onSubmit}>Save</Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default ClientFormFields;
