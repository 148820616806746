/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  FilterOperation,
  GetClientsListQuery,
  GetPhonesListQuery,
  GetProjectsListQuery,
  PhoneQueryFilterFields,
  Provider,
  useGetClientsListLazyQuery,
  useGetPhonesListLazyQuery,
  GetClientsListQueryVariables,
  GetProjectsListQueryVariables,
  ListFilterQueryFilterFields,
  useGetListFiltersListLazyQuery,
  useGetProjectsListLazyQuery
} from "../../../generated/graphql";
import {
  CLIENT_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW,
  clientTabFilters
} from "../../../constants/clientConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import {
  ClientTableColumns,
  clientTableDataFormatter
} from "../../../components/Client/ClientTable";
import { phoneTableDataFormatter } from "../../../components/Phone/PhoneTable";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";
import { PAGE_SIZE } from "../../../utils";
import {
  FiltersTableColumns,
  listFilterTableDataFormatter
} from "../../../components/ListFilter/ListFilterTable";
import { message } from "../../../components/ListFilter/utils";

interface ClientViewProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const purchasedPhonesColumns = [
  {
    id: "phoneTypeId",
    header: "Provider",
    accessorKey: "phoneType"
  },
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number"
  },
  {
    id: "phoneStatusId",
    header: "Status",
    accessorKey: "phoneStatus"
  }
];

const contactPhonesColumns = [
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number"
  },
  {
    id: "description",
    header: "Contact Description",
    accessorKey: "description"
  }
];

const ClientShow = ({ id, ...props }: ClientViewProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  const [
    ,
    { data: clientsData, loading: loadingClients, refetch: refetchClients }
  ] = useGetClientsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: clientTabFilters({ id, selectedTab: LIST_VIEW.AGENCIES })
      .variables as GetClientsListQueryVariables,
    onCompleted: (response: GetClientsListQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages && props.setTotalPages(response.clients.totalCount);
    }
  });

  const handleClientRefetch = (options: GetClientsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchClients(options);
  };

  const [
    ,
    { data: filtersData, loading: loadingFilters, refetch: refetchFilters }
  ] = useGetListFiltersListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: ListFilterQueryFilterFields.ClientId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const [
    ,
    { data: projectsData, loading: loadingProjects, refetch: refetchProjects }
  ] = useGetProjectsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: clientTabFilters({ id, selectedTab: LIST_VIEW.PROJECTS })
      .variables as GetProjectsListQueryVariables,
    onCompleted: (response: GetProjectsListQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages && props.setTotalPages(response.projects.totalCount);
    }
  });

  const handleProjectRefetch = (options: GetProjectsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchProjects(options);
  };

  const [
    ,
    {
      data: contactPhonesData,
      loading: loadingContactPhones,
      refetch: refetchContactPhones
    }
  ] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: PhoneQueryFilterFields.ClientId,
          operation: FilterOperation.Equal,
          value: id
        },
        {
          field: PhoneQueryFilterFields.PhoneTypeId,
          operation: FilterOperation.Equal,
          value: "PhoneType::::Client"
        }
      ]
    },
    onCompleted: (response: GetPhonesListQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages && props.setTotalPages(response.phones.totalCount);
    }
  });

  const [
    ,
    {
      data: purchasedPhonesData,
      loading: loadingPurchasedPhones,
      refetch: refetchPurchasedNumbers
    }
  ] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: PhoneQueryFilterFields.ClientId,
          operation: FilterOperation.Equal,
          value: id
        },
        {
          field: PhoneQueryFilterFields.PhoneTypeId,
          operation: FilterOperation.In,
          arrayValues: Object.values(Provider).map(
            provider => `PhoneType::::${provider}`
          )
        }
      ]
    },
    onCompleted: (response: GetPhonesListQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages && props.setTotalPages(response.phones.totalCount);
    }
  });

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === LIST_VIEW.OVERVIEW)
    props.setTotalPages(null);

  useEffect(() => {
    switch (props.activeLinkId) {
      case LIST_VIEW.AGENCIES:
        void refetchClients().then(() => {
          resourceTabsProviderSetValue(
            clientTabFilters({ id, selectedTab: LIST_VIEW.AGENCIES })
              .variables as GetClientsListQueryVariables
          );
        });
        return;
      case LIST_VIEW.PROJECTS:
        void refetchProjects().then(() => {
          resourceTabsProviderSetValue(
            clientTabFilters({ id, selectedTab: LIST_VIEW.PROJECTS })
              .variables as GetProjectsListQueryVariables
          );
        });
        return;
      case LIST_VIEW.LIST_FILTERS:
        void refetchFilters();
        return;
      case LIST_VIEW.CONTACT_PHONES:
        void refetchContactPhones();
        return;
      case LIST_VIEW.PURCHASED_NUMBERS:
        void refetchPurchasedNumbers();
        return;
      default:
        return;
    }
  }, [props.activeLinkId]);

  switch (props.activeLinkId) {
    case LIST_VIEW.OVERVIEW:
      return <DataOverview />;
    case LIST_VIEW.AGENCIES:
      return (
        <List
          accessor="clients"
          columns={ClientTableColumns}
          data={clientsData}
          dataFormat={clientTableDataFormatter}
          loading={loadingClients}
          refetchQuery={handleClientRefetch}
        />
      );
    case LIST_VIEW.PROJECTS:
      return (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectsData}
          dataFormat={projectTableDataFormatter}
          loading={loadingProjects}
          refetchQuery={handleProjectRefetch}
        />
      );
    case LIST_VIEW.LIST_FILTERS:
      return (
        <>
          <List
            accessor="listFilters"
            columns={FiltersTableColumns}
            data={filtersData}
            dataFormat={data => listFilterTableDataFormatter(data, "Client")}
            loading={loadingFilters}
            refetchQuery={refetchFilters}
          />
          <div>{message("Client")}</div>
        </>
      );
    case LIST_VIEW.CONTACT_PHONES:
      return (
        <List
          accessor="phones"
          columns={contactPhonesColumns}
          data={contactPhonesData}
          dataFormat={phoneTableDataFormatter}
          loading={loadingContactPhones}
          refetchQuery={refetchContactPhones}
        />
      );
    case LIST_VIEW.PURCHASED_NUMBERS:
      return (
        <List
          accessor="phones"
          columns={purchasedPhonesColumns}
          data={purchasedPhonesData}
          dataFormat={phoneTableDataFormatter}
          loading={loadingPurchasedPhones}
          refetchQuery={refetchPurchasedNumbers}
        />
      );
    case LIST_VIEW.OPT_OUTS:
      return <></>;
    default:
      return <></>;
  }
};

export default ClientShow;
