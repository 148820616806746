import {
  ClientQueryFilterFields,
  FilterOperation,
  GetClientsListCsvDocument,
  GetProjectsListCsvDocument,
  ProjectQueryFilterFields
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";
export const CLIENTS_PATH = "clients";
export const CLIENT_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  PROJECTS: "Projects",
  AGENCIES: "Agencies",
  LIST_FILTERS: "List Filters",
  CONTACT_PHONES: "Contact Phones",
  PURCHASED_NUMBERS: "Purchased Numbers",
  OPT_OUTS: "Opt-Outs"
};

export const clientTabFilters = ({ id, selectedTab }: BaseFilterTabsProps) => {
  const filterOptions = {
    [CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ClientQueryFilterFields.AgencyId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetClientsListCsvDocument
    },
    [CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ProjectQueryFilterFields.ClientId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetProjectsListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
