import { Moment } from "moment";
import momenttz from "moment-timezone";
import DOMPurify from "dompurify";
import he from "he";
import { convertFromHTMLToString } from "@onehq/anton";
import { ProjectFieldsFragment } from "../../generated/graphql";

// interfaces
// when we added the "_omitSubmit" option to the normalize return,
// we added some scenarios that could omit the submit
// e.g. omit submit if field "name" is not null but "lastname" is null
// but what if those are irrelevant values that has no meaning alone?
// and what if we change something else like "birthday"?
// we could like to continue with the submit and ignore the changes in "name",
// in order to continue with the autosave
// one way to achieve that is to check if one section has an omit submit scenario
// and then check if another section has changes,
// then ignore the section with omit-submit and continue with the change of the other sections
// let's start defining the sections of the project
export type ProjectSection =
  | "Basic Info"
  | "Message"
  | "Sending From"
  | "Sending Schedule"
  | "Texters";

export type TimeZone = ProjectFieldsFragment["timeZone"];
export type TimeZoneInput = { id: string; pgTimeZoneName: string };

// helper functions
// necessary because the rich text field library works with html underneath
// also add spaces to empty lines (fix for iPhone 14 Pro Max omitting empty lines)
export function htmlToString(message: string) {
  const plain = convertFromHTMLToString(message);
  // remove leading/trailing spaces
  let trimmed = plain.replaceAll(/^\s+|\s+$/g, "");
  // add spaces to empty lines (fix for iPhone 14 Pro Max omitting empty lines)
  const fix = (str: string) => str.replaceAll("\n\n", "\n       \n");
  // called many times bc this case: "\n\n\n" => "\n   \n\n"
  while (trimmed !== fix(trimmed)) trimmed = fix(trimmed);
  return trimmed;
}

// formats db date to datefield format
export function formatTime(
  datetime?: string | null,
  timeZone?: TimeZone | null
) {
  if (datetime && timeZone) {
    return momenttz(datetime).tz(timeZone?.pgTimezoneName);
  } else return undefined;
}

// formats datefield date to db format
export function normalizeDate(
  date?: string,
  time?: Moment,
  timeZone?: TimeZoneInput
) {
  if (date && time && timeZone) {
    const zone = timeZone.pgTimeZoneName;
    const moment = momenttz.tz(`${date} ${time.format("HH:mm:ss")}`, zone);
    return moment.utc();
  } else return null;
}

// converts html to plain text
// it also:
// - adds a line break between <p>s
// - converts <strong> characters into unicode bold characters
// - converts <em> characters into unicode italic characters
// - converts nested <strong>s <em>s into unicod bold italic characters
// - fix bug - iPhone 14 Pro omitting empty lines
export function htmlToUnicode(html?: string) {
  if (!html) return undefined;
  let text = sanitizeHtml(html);
  // replace </p><p> with a newline for separation
  text = text.replaceAll("</p><p>", "\n</p><p>");
  // replace <br> tags with seven spaces (fix iPhone 14 Pro bug - omitting empty lines)
  text = text.replaceAll("<br>", "       ");

  // parse the HTML string into a DOM structure
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");

  // traverse the DOM structure starting from the body and return the formatted text
  return elementToUnicode(doc.body);
}

// traverse the nodes
function elementToUnicode(node: Node) {
  let result = "";

  // process the current node's text content if it's an Element
  if (node.nodeType === Node.TEXT_NODE) {
    let formattedText = node.textContent || "";

    const ancestors: string[] = [];
    let parent = node.parentElement;
    while (parent) {
      ancestors.push(parent.tagName);
      parent = parent.tagName !== "BODY" ? parent.parentElement : null;
    }

    if (ancestors.includes("STRONG") && ancestors.includes("EM")) {
      formattedText = toUnicodeBoldItalic(formattedText);
    } else if (ancestors.includes("STRONG")) {
      formattedText = toUnicodeBold(formattedText);
    } else if (ancestors.includes("EM")) {
      formattedText = toUnicodeItalic(formattedText);
    }

    result += formattedText;
  } else {
    // recursively process each child node
    node.childNodes.forEach(child => {
      result += elementToUnicode(child);
    });
  }

  return result;
}

// convert text to Unicode bold
function toUnicodeBold(text = "") {
  return [...text]
    .map(char => {
      const code = char.charCodeAt(0);
      if (code >= 65 && code <= 90) {
        return String.fromCodePoint(0x1d5d4 + (code - 65)); // a-Z
      } else if (code >= 97 && code <= 122) {
        return String.fromCodePoint(0x1d5ee + (code - 97)); // a-z
      } else if (code >= 48 && code <= 57) {
        return String.fromCodePoint(0x1d7ec + (code - 48)); // 0-9
      } else {
        return char; // leave special characters as-is
      }
    })
    .join("");
}

// convert text to Unicode italic
function toUnicodeItalic(text = "") {
  return [...text]
    .map(char => {
      const code = char.charCodeAt(0);
      if (code >= 65 && code <= 90) {
        return String.fromCodePoint(0x1d608 + (code - 65)); // a-Z
      } else if (code >= 97 && code <= 122) {
        return String.fromCodePoint(0x1d622 + (code - 97)); // a-z
      } else {
        return char; // leave numbers and special characters as-is
      }
    })
    .join("");
}

// convert text to Unicode bold italic
function toUnicodeBoldItalic(text = "") {
  return [...text]
    .map(char => {
      const code = char.charCodeAt(0);
      if (code >= 65 && code <= 90) {
        return String.fromCodePoint(0x1d63c + (code - 65)); // a-Z
      } else if (code >= 97 && code <= 122) {
        return String.fromCodePoint(0x1d656 + (code - 97)); // a-z
      } else if (code >= 48 && code <= 57) {
        return String.fromCodePoint(0x1d7ec + (code - 48)); // 0-9 (only bold)
      } else {
        return char; // leave special characters as-is
      }
    })
    .join("");
}

// define the allowed tags (body is there only to bypass it)
const allowedTags = ["P", "STRONG", "EM", "SPAN", "BR", "BODY"];

// replace not allowed html tags with <p> tags
// remove html attributes (they are not needed)
// replace html codes with unicode characters (like &amp; to &)
function sanitizeHtml(htmlString = "") {
  const secureHtml = DOMPurify.sanitize(htmlString);
  // parse the HTML string into a DOM
  const parser = new DOMParser();
  const doc = parser.parseFromString(secureHtml, "text/html");
  sanitizeElement(doc.body);

  return he.decode(doc.body.innerHTML);
}

function sanitizeElement(node: Element) {
  let element = node;
  if (element.nodeType === Node.ELEMENT_NODE) {
    // remove all attributes
    Array.from(element.attributes).forEach(attr =>
      element.removeAttribute(attr.name)
    );

    // replace non-allowed tags with <p>s
    if (!allowedTags.includes(element.tagName)) {
      const newElement = document.createElement("p");
      newElement.innerHTML = element.innerHTML;
      element.replaceWith(newElement);
      element = newElement;
    }
  }

  // traverse child nodes
  Array.from(element.childNodes).forEach((child: Element) => {
    sanitizeElement(child);
  });
}

// converts plain text to html
// it also:
// - separate line breaks with <p>s
// - converts unicode bold characters into <strong> characters
// - converts unicode italic characters into <em> characters
// - converts unicode bold italic characters into nested <strong>s <em>s
// - fix bug - iPhone 14 Pro omitting empty lines
export function unicodeToHtml(text?: string) {
  if (!text) return undefined;
  let html = text;
  // replace empty lines with <br> (fix for iPhone 14 Pro Max omitting empty lines)
  html = html.replaceAll("\n       \n", "\n<br>\n");

  // wrap lines with <p></p>
  html = html
    .split("\n")
    .map(line => `<p>${line}</p>`)
    .join("");

  // wrap bold with <strong></strong>
  html = unicodeBoldToHtml(html);

  // wrap italic with <em></em>
  html = unicodeItalicToHtml(html);

  // unicode characters to normal characters
  html = unicodeToNormal(html);

  return html;
}

function unicodeBoldToHtml(text = "") {
  const boldStringRegex = /([𝗔-𝗭𝗮-𝘇𝘼-𝙕𝙖-𝙯𝟬-𝟵]+(?:\s+[𝗔-𝗭𝗮-𝘇𝘼-𝙕𝙖-𝙯𝟬-𝟵]+)*)/gu;
  return text.replaceAll(boldStringRegex, "<strong>$1</strong>");
}

function unicodeItalicToHtml(text = "") {
  const italicStringRegex = /([𝘈-𝘡𝘢-𝘻𝘼-𝙕𝙖-𝙯]+(?:\s+[𝘈-𝘡𝘢-𝘻𝘼-𝙕𝙖-𝙯]+)*)/gu;
  return text.replaceAll(italicStringRegex, "<em>$1</em>");
}

function unicodeToNormal(text = "") {
  return text.replace(/[𝗔-𝗭𝗮-𝘇𝘈-𝘡𝘢-𝘻𝘼-𝙕𝙖-𝙯𝟬-𝟵]/gu, char => {
    const code = char.codePointAt(0) || 0;

    // convert bold (sans-serif)
    if (code >= codeOf("𝗔") && code <= codeOf("𝗭")) {
      return charOf(code - codeOf("𝗔") + 65); // uppercase
    }
    if (code >= codeOf("𝗮") && code <= codeOf("𝘇")) {
      return charOf(code - codeOf("𝗮") + 97); // lowercase
    }

    // convert italic (sans-serif)
    if (code >= codeOf("𝘈") && code <= codeOf("𝘡")) {
      return charOf(code - codeOf("𝘈") + 65); // uppercase
    }
    if (code >= codeOf("𝘢") && code <= codeOf("𝘻")) {
      return charOf(code - codeOf("𝘢") + 97); // lowercase
    }

    // convert bold-italic (sans-serif)
    if (code >= codeOf("𝘼") && code <= codeOf("𝙕")) {
      return charOf(code - codeOf("𝘼") + 65); // uppercase
    }
    if (code >= codeOf("𝙖") && code <= codeOf("𝙯")) {
      return charOf(code - codeOf("𝙖") + 97); // lowercase
    }

    // convert bold numbers (sans-serif)
    if (code >= codeOf("𝟬") && code <= codeOf("𝟵")) {
      return charOf(code - codeOf("𝟬") + 48); // numbers
    }

    return char; // leave other characters as is
  });
}

function codeOf(char: string) {
  return char.codePointAt(0) || 0;
}

function charOf(code: number) {
  return String.fromCharCode(code);
}