// not paginated table (used in ListPage what already paginates this component)
// react modules
import React from "react";

// third-party modules
import {
  badgeCell,
  BadgeTheme,
  Button,
  CellDataType,
  NoWrapCell,
  Switch,
  Table
} from "@onehq/anton";
import {
  ADD,
  GrowlAlertDispatch,
  Link,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  CreateOptOutMutation,
  CustomCurrentUserQuery,
  DestroyOptOutMutation,
  MessagingServiceType,
  OptOutType,
  TextListFieldsFragment,
  TextDetailedListFieldsFragment,
  TextType,
  useCreateOptOutMutation,
  useDestroyOptOutMutation,
  OptOutFieldsFragmentDoc
} from "../../generated/graphql";
import {
  addSpacesBetweenWords,
  formatDate,
  formatPhone,
  formatPhoneWithProviderCircle,
  textStatusBadgeColor
} from "../../utils";
import { BaseTableProps } from "../../types";
import { PHONES_PATH, PROJECTS_PATH, TEXTS_PATH } from "../../constants";
import { Reference, StoreObject } from "@apollo/client";

export enum TypesOfTextList {
  error = "error", // add error column to default columns
  default = "default" // dont change default columns
}

type TextFragment = TextDetailedListFieldsFragment | TextListFieldsFragment;

export interface TextTableProps extends BaseTableProps<TextFragment> {
  currentUser?: CustomCurrentUserQuery["currentUser"];
  type?: TypesOfTextList;
}

export interface TextTableColumnsProps {
  showError?: boolean;
  testTextTable?: boolean;
}

export const getTextTableColumns = ({
  showError,
  testTextTable
}: TextTableColumnsProps = {}) => {
  const columns: any[] = [
    {
      id: "openButton",
      header: "Text",
      accessorKey: "openButton",
      enableSorting: false
    },
    {
      id: "texter",
      header: "Texter",
      accessorKey: "texter",
      enableSorting: false
    },
    {
      id: "body",
      header: "Body",
      accessorKey: "renderedMessage",
      cell: NoWrapCell,
      columnMaxWidth: 310
    },
    {
      id: "messagingServiceTypeId",
      header: "Service Type",
      accessorKey: "messagingServiceType"
    },
    {
      id: "textStatusId",
      header: "Text Status",
      accessorKey: "textStatus"
    },
    {
      id: "fromPhone",
      header: "From Phone Number",
      accessorKey: "fromPhone",
      enableSorting: false
    },
    {
      id: "toPhone",
      header: "To Phone Number",
      accessorKey: "toPhone",
      enableSorting: false
    },
    {
      id: "sentAt",
      header: "Sent At",
      accessorKey: "sentAt"
    },
    {
      id: "trackingId",
      header: "Tracking ID",
      accessorKey: "trackingId",
      enableSorting: false
    },
    {
      id: "projectNumber",
      header: "Project Number",
      accessorKey: "projectNumber",
      enableSorting: false
    },
    {
      id: "projectState",
      header: "Project State",
      accessorKey: "projectState",
      enableSorting: false
    },
    {
      id: "projectName",
      header: "Project Name",
      accessorKey: "projectName",
      enableSorting: false
    }
  ];

  // add error message if showError is true
  if (showError)
    columns.push({
      id: "errorMessage",
      header: "Error Message",
      accessorKey: "errorMessage",
      dataType: "longText" as CellDataType
    });
  if (testTextTable)
    columns.push({
      id: "resendButton",
      header: "Action",
      accessorKey: "resendButton",
      enableSorting: false
    });
  // add opt out button as last column
  columns.push({
    id: "optOutButton",
    header: "Opt Out",
    accessorKey: "optOutButton",
    enableSorting: false
  });

  return columns;
};

export const useTextTableDataFormatter = (
  data?: Array<TextFragment>,
  openResend?: (
    toPhone: TextFragment["toPhoneNumber"],
    fromPhone: TextFragment["fromPhoneNumber"],
    message: string
  ) => void,
  queryName: "texts" | "optOutTexts" = "texts"
) => {
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  // action for creating optOut
  const [createOptOut] = useCreateOptOutMutation({
    onCompleted: (response: CreateOptOutMutation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (Object.keys(response?.createOptOut?.errors as Object).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: `Opt out has been created successfully`,
            variant: "success"
          }
        });
      } else {
        alert({
          type: ADD,
          payload: {
            title: "Error creating opt out",
            variant: "error"
          }
        });
      }
    },
    update(cache, { data }) {
      const newOptOut = data?.createOptOut?.resource;
      if (!newOptOut) return;

      const { id, phoneId, clientId } = newOptOut;

      cache.writeFragment({
        id: `OptOut:${id}`,
        fragmentName: "OptOutFields",
        fragment: OptOutFieldsFragmentDoc,
        data: newOptOut
      });

      cache.modify({
        fields: {
          [queryName]: (existingTextRefs: any, { readField }) => {
            const nodes = existingTextRefs?.nodes || [];
            if (!nodes) return existingTextRefs;
            nodes.forEach((ref: StoreObject | Reference) => {
              const textType = readField("textType", ref);
              const phoneType = textType === TextType.Reply ? "from" : "to";
              const phoneKey = `${phoneType}PhoneId`;

              const project = readField("project", ref) as Readonly<Object>;
              const sameClient = readField("clientId", project) === clientId;
              const samePhone = readField(phoneKey, ref) === phoneId;
              if (samePhone && sameClient) {
                cache.modify({
                  id: ref.__ref as any,
                  fields: {
                    optOut: () => ({ __ref: `OptOut:${id}` })
                  }
                });
              }
            });
            return existingTextRefs;
          }
        }
      });
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  // action for deleting optOut
  const [destroyOptOut] = useDestroyOptOutMutation({
    onCompleted: (response: DestroyOptOutMutation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (Object.keys(response?.destroyOptOut?.errors as Object).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: `Opt out has been deleted successfully`,
            variant: "success"
          }
        });
      } else {
        alert({
          type: ADD,
          payload: {
            title: "Error removing opt out",
            variant: "error"
          }
        });
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    },
    update(cache, { data }) {
      const resource = data?.destroyOptOut?.resource;
      if (resource) {
        const { phoneId, clientId } = resource as any;
        cache.modify({
          fields: {
            [queryName]: (existingTextRefs: any, { readField }) => {
              const nodes = existingTextRefs?.nodes || [];
              if (!nodes) return existingTextRefs;
              nodes.forEach((ref: StoreObject | Reference) => {
                const textType = readField("textType", ref);
                const phoneType = textType === TextType.Reply ? "from" : "to";
                const phoneKey = `${phoneType}PhoneId`;

                const project = readField("project", ref) as Readonly<Object>;
                const sameClient = readField("clientId", project) === clientId;
                const samePhone = readField(phoneKey, ref) === phoneId;
                if (samePhone && sameClient) {
                  cache.modify({
                    id: ref.__ref as any,
                    fields: {
                      optOut: () => null
                    }
                  });
                }
              });
              return existingTextRefs;
            }
          }
        });
      }
    }
  });

  // this button will render in the table
  const optOutButton = (item: TextFragment) => {
    const theresOptOut = item.optOut?.id;
    const onClick = () => {
      if (theresOptOut) {
        destroyOptOut({
          variables: {
            id: theresOptOut
          }
        }).catch(() => {
          console.log("error");
        });
      } else {
        createOptOut({
          variables: {
            attributes: {
              textId: item.id,
              clientId: item.project.client.id,
              phoneId:
                item.textType === TextType.Reply
                  ? item.fromPhoneNumber?.id
                  : item.toPhoneNumber?.id,
              optOutType: OptOutType.Manual
            }
          }
        }).catch(() => {
          console.log("error");
        });
      }
    };
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Switch checked={!!theresOptOut} onChange={onClick} />
      </div>
    );
  };

  const resendButton = (
    toPhone: TextFragment["toPhoneNumber"],
    fromPhone: TextFragment["fromPhoneNumber"],
    message: string
  ) => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => openResend && openResend(toPhone, fromPhone, message)}
          variant="icon"
        >
          Resend
        </Button>
      </div>
    );
  };

  return [
    ...(data?.map((item: TextFragment) => {
      const {
        id,
        serviceType,
        project,
        renderedMessage,
        texter,
        textStatus,
        trackingId,
        fromPhoneNumber,
        toPhoneNumber,
        sentAt,
        optOut
      } = item;

      return {
        projectName: (
          <Link to={`/${PROJECTS_PATH}/${project?.id}/overview`}>
            {project?.name}
          </Link>
        ),
        projectNumber: project.number,
        projectState: project.state?.name,
        texter: <Link to={`/users/${texter?.id}`}>{texter?.name}</Link>,
        renderedMessage: renderedMessage || "--",
        messagingServiceType: badgeCell({
          value: serviceType || "",
          color:
            serviceType === MessagingServiceType.Mms
              ? "blue"
              : ("green" as BadgeTheme)
        }),
        textStatus: badgeCell({
          value: addSpacesBetweenWords(textStatus),
          color: textStatusBadgeColor(textStatus) as BadgeTheme
        }),
        fromPhone: (
          <Link to={`/${PHONES_PATH}/${fromPhoneNumber?.id}`}>
            {!!fromPhoneNumber &&
              formatPhoneWithProviderCircle(fromPhoneNumber)}
          </Link>
        ),
        toPhone: (
          <Link to={`/${PHONES_PATH}/${toPhoneNumber?.id}`}>
            {formatPhone(toPhoneNumber?.number || "")}
          </Link>
        ),
        trackingId,
        sentAt: formatDate(sentAt),
        optOut,
        optOutButton: optOutButton(item),
        resendButton: resendButton(
          item.toPhoneNumber,
          item.fromPhoneNumber,
          item.body || ""
        ),
        openButton: <Link to={`/${TEXTS_PATH}/${id}/overview`}>open</Link>,
        errorMessage: item.errorMessage
      };
    }) || [])
  ];
};

const TextTable = (props: TextTableProps) => {
  const data = props.data || [];
  const loading = props.loading;
  const type = props.type || TypesOfTextList.default;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={getTextTableColumns({
        showError: type === TypesOfTextList.error
      })}
      data={useTextTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default TextTable;
