import React from "react";

import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  ORGANIZATIONS_PATH,
  ORGANIZATION_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../constants";
import sections from "./OrganizationEdit/sections";
import OrganizationEdit from "./OrganizationEdit";
import OrganizationAnchor from "./OrganizationAnchor";
import OrganizationShow from "./OrganizationShow";
import { ExportButton } from "../../components/Actions/Export";

const OrganizationsRoute = new ResourceRoute({
  name: "Organization",
  path: ORGANIZATIONS_PATH,
  AnchorComponent: OrganizationAnchor,
  EditComponent: { component: OrganizationEdit, anchorLinks: sections },
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.OVERVIEW} />
      )
    }),
    new NestedResourceRoute({
      name: "Purchased Numbers",
      path: "purchased_numbers",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.PURCHASED_NUMBERS} />
      )
    }),
    new NestedResourceRoute({
      name: "Text Defaults",
      path: "text_defaults",
      baseRoute: "Organization",
      actions: (
        <ExportButton
          baseResource={"Organization"}
          variant={VIEW_LINKS.TEXT_DEFAULTS}
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.TEXT_DEFAULTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Domains",
      path: "domains",
      baseRoute: "Organization",
      actions: (
        <ExportButton
          baseResource={"Organization"}
          variant={VIEW_LINKS.DOMAINS}
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.DOMAINS} />
      )
    }),
    new NestedResourceRoute({
      name: "Unreg. Campaigns",
      path: "unregistered-campaigns",
      baseRoute: "Organization",
      actions: (
        <ExportButton
          baseResource={"Organization"}
          variant={VIEW_LINKS.UNREGISTERED_CAMPAIGNS}
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow
          id={id}
          activeLinkId={VIEW_LINKS.UNREGISTERED_CAMPAIGNS}
        />
      )
    }),
    new NestedResourceRoute({
      name: "List Filters",
      path: "list-filters",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.LIST_FILTERS} />
      )
    }),
    new NestedResourceRoute({
      name: "Users",
      path: "users",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.USERS} />
      )
    }),
    new NestedResourceRoute({
      name: "Teams",
      path: "teams",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.TEAMS} />
      )
    }),
    new NestedResourceRoute({
      name: "Audit Report",
      path: "audit-report",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.AUDIT_REPORT} />
      )
    }),
    new NestedResourceRoute({
      name: "Billing Report",
      path: "billing-report",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.BILLING_REPORT} />
      )
    }),
    new NestedResourceRoute({
      name: "Provider Colors",
      path: "provider-colors",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.PROVIDER_COLORS} />
      )
    })
  ]
});

export { OrganizationsRoute };
