/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { badgeCell, BadgeTheme, CellDataType, Table } from "@onehq/anton";
import { Link } from "@onehq/framework";
import { ClientListFieldsFragment } from "../../generated/graphql";
import {
  addSpacesBetweenWords,
  formatDate,
  clientStatusBadgeColor
} from "../../utils";
import { BaseTableProps } from "../../types";
import { htmlToString } from "../Project/utils";

export const ClientTableColumns = [
  {
    header: "Name",
    id: "name",
    accessorKey: "name"
  },
  {
    header: "Type",
    id: "clientTypeId",
    accessorKey: "clientType"
  },
  {
    header: "Status",
    id: "clientStatusId",
    accessorKey: "clientStatus"
  },
  {
    header: "Agency",
    id: "agency",
    accessorKey: "agency",
    enableSorting: false
  },
  {
    header: "Note",
    id: "note",
    accessorKey: "note",
    dataType: "longText" as CellDataType
  },
  {
    header: "Created At",
    id: "createdAt",
    accessorKey: "createdAt"
  }
];

export const clientTableDataFormatter = (
  data?: Array<ClientListFieldsFragment>
) => [
  ...(data?.map((item: ClientListFieldsFragment) => {
    const { id, name, note, agency, type, status, createdAt } = item;
    return {
      name: <Link to={`/clients/${id}/overview`}>{name}</Link>,
      note: htmlToString(note || ""),
      clientStatus: badgeCell({
        value: addSpacesBetweenWords(status),
        color: clientStatusBadgeColor(status) as BadgeTheme
      }),
      agency: <Link to={`/clients/${agency?.id}`}>{agency?.name}</Link>,
      clientType: addSpacesBetweenWords(type),
      createdAt: formatDate(createdAt)
    };
  }) || [])
];

export type ClientTableProps = BaseTableProps<ClientListFieldsFragment>;

const ClientTable = ({ data = [], ...props }: ClientTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={ClientTableColumns}
      data={clientTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default ClientTable;
