/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { LinkId } from "@onehq/anton";

import {
  CustomDomainListFieldsFragment,
  FilterOperation,
  GetCampaignsListQueryVariables,
  GetCustomDomainsListQueryVariables,
  GetProviderColorListQueryVariables,
  GetTextDefaultsListQueryVariables,
  ProviderColorQueryFieldsFragment,
  ListFilterQueryFilterFields,
  useGetCampaignsListLazyQuery,
  useGetCustomDomainsListLazyQuery,
  useGetProviderColorListQuery,
  useGetListFiltersListLazyQuery,
  useGetTextDefaultsListLazyQuery,
  GetUsersListQueryVariables,
  useGetUsersListLazyQuery,
  useGetTeamsListLazyQuery,
  PhoneQueryFilterFields,
  ClientQueryFilterFields,
  useGetPhonesListQuery,
  Provider,
  useGetClientsListLazyQuery
} from "../../../generated/graphql";
import {
  ORGANIZATION_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW,
  organizationTabFilters
} from "../../../constants";
import { addSpacesBetweenWords, PAGE_SIZE } from "../../../utils";
import DataOverview from "../../../components/pages/DataOverview";
import { List } from "@onehq/framework";
import {
  TextDefaultTableColumns,
  textDefaultTableDataFormatter
} from "../../../components/TextDefault/TextDefaultTable";
import {
  CampaignTableColumns,
  campaignTableDataFormatter
} from "../../../components/Campaign/CampaignTable";
import AuditReportForm from "../../auditReport/auditReport";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";
import styled from "styled-components";
import { spacingTheme } from "@onehq/style";
import {
  FiltersTableColumns,
  listFilterTableDataFormatter
} from "../../../components/ListFilter/ListFilterTable";
import { message } from "../../../components/ListFilter/utils";
import BillingReportForm from "../../billingReport/billingReport";
import {
  UserTableColumns,
  userTableDataFormatter
} from "../../../components/User/UserTable";
import {
  TeamTableColumns,
  teamTableDataFormatter
} from "../../../components/Team/TeamTable";
import { phoneTableDataFormatter } from "../../../components/Phone/PhoneTable";

export const DomainTableColumns = [
  {
    header: "Domain",
    accessorKey: "domain",
    enableSorting: false
  }
];

export const ProviderColorsTableColumns = [
  {
    header: "Provider",
    accessorKey: "provider",
    enableSorting: false
  },
  {
    header: "Color",
    accessorKey: "color",
    enableSorting: false
  }
];

const campaignPhonesColumns = [
  {
    id: "phoneTypeId",
    header: "Provider",
    accessorKey: "phoneType"
  },
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number"
  },
  {
    id: "phoneStatusId",
    header: "Status",
    accessorKey: "phoneStatus"
  }
];

export const DomainTableDataFormatter = (
  data?: Array<CustomDomainListFieldsFragment>
) => [
  ...(data?.map((item: CustomDomainListFieldsFragment) => {
    return { domain: item.domain };
  }) || [])
];

const FlexContainter = styled.div`
    display: flex;
    width: fit-content;
    justify-content: center;
    gap: 6px;
`;

const ProviderColorShow = styled.div<{ color: string }>`
    height: ${spacingTheme.spacing20};
    width: ${spacingTheme.spacing20};
    border-radius: 2px;
    background-color: ${props => props.color};
`;

export const ProviderColorsTableDataFormatter = (
  data?: Array<ProviderColorQueryFieldsFragment>
) => [
  ...(data?.map((item: ProviderColorQueryFieldsFragment) => ({
    ...item,
    provider: addSpacesBetweenWords(item.provider),
    color: (
      <FlexContainter>
        <ProviderColorShow color={item.color} />
        {item.color.toLocaleUpperCase()}
      </FlexContainter>
    )
  })) || [])
];

const campaignsTableColumns = CampaignTableColumns.filter(
  c => c.accessorKey !== "code"
);

interface OrganizationShowProps {
  id: string;
  activeLinkId: LinkId;
}

const OrganizationShow = ({ activeLinkId, id }: OrganizationShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();
  const [clientsOrganization, setClientsOrganization] = useState<any>([]);

  const [
    ,
    {
      data: textDefaultsData,
      loading: loadingTextDefaults,
      refetch: refetchTextDefaults
    }
  ] = useGetTextDefaultsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.TEXT_DEFAULTS
    }).variables as GetTextDefaultsListQueryVariables
  });

  const handleTextDefaultsRefetch = (
    options: GetTextDefaultsListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchTextDefaults(options);
  };

  const [
    ,
    {
      data: domainsData,
      loading: loadingDomains,
      refetch: refetchCustomDomains
    }
  ] = useGetCustomDomainsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({ id, selectedTab: LIST_VIEW.DOMAINS })
      .variables as GetCustomDomainsListQueryVariables
  });

  const handleCustomDomainsRefetch = (
    options: GetCustomDomainsListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchCustomDomains(options);
  };

  const [
    ,
    {
      data: campaignsData,
      loading: loadingCampaigns,
      refetch: refetchCampaigns
    }
  ] = useGetCampaignsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.UNREGISTERED_CAMPAIGNS
    }).variables as GetCampaignsListQueryVariables
  });

  const handleCampaignsRefetch = (options: GetCampaignsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchCampaigns(options);
  };

  const [
    ,
    { data: filtersData, loading: loadingFilters, refetch: refetchFilters }
  ] = useGetListFiltersListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: ListFilterQueryFilterFields.ClientId,
          operation: FilterOperation.With,
          value: "false"
        },
        {
          field: ListFilterQueryFilterFields.ProjectId,
          operation: FilterOperation.With,
          value: "false"
        }
      ]
    }
  });

  const {
    data: providerColorsData,
    loading: loadingProviderColors,
    refetch: refetchProviderColors
  } = useGetProviderColorListQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.PROVIDER_COLORS
    }).variables as GetProviderColorListQueryVariables
  });

  const handleProviderColorsRefetch = (
    options: GetProviderColorListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchProviderColors(options);
  };

  const [, { data: usersData, loading: loadingUsers, refetch: refetchUsers }] =
    useGetUsersListLazyQuery({
      fetchPolicy: "cache-and-network",
      variables: organizationTabFilters({
        id,
        selectedTab: LIST_VIEW.USERS
      }).variables as GetUsersListQueryVariables
    });

  const handleUsersRefetch = (options: GetUsersListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchUsers(options);
  };

  const [, { data: teamsData, loading: loadingTeams, refetch: refetchTeams }] =
    useGetTeamsListLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const [, { refetch: clientsRefetch }] = useGetClientsListLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: {
        field: ClientQueryFilterFields.OrganizationId,
        operation: FilterOperation.Equal,
        value: id
      }
    }
  });

  useEffect(() => {
    clientsRefetch().then(response => {
      const clientsId =
        response.data?.clients?.nodes?.map(
          client => client?.id) || [];
      setClientsOrganization(clientsId);
    })
      .catch(error => console.log(error));
  }, [id]);

  const { data, loading, refetch } = useGetPhonesListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: PhoneQueryFilterFields.ClientId,
          operation: FilterOperation.In,
          arrayValues: clientsOrganization
        },
        {
          field: PhoneQueryFilterFields.PhoneTypeId,
          operation: FilterOperation.In,
          arrayValues: Object.values(Provider).map(
            provider => `PhoneType::::${provider}`
          )
        }
      ]
    }
  });

  useEffect(() => {
    switch (activeLinkId) {
      case LIST_VIEW.LIST_FILTERS:
        void refetchFilters();
        return;
      case LIST_VIEW.DOMAINS:
        void refetchCustomDomains().then(() => {
          resourceTabsProviderSetValue(
            organizationTabFilters({ id, selectedTab: LIST_VIEW.DOMAINS })
              .variables as GetCustomDomainsListQueryVariables
          );
        });
        return;
      case LIST_VIEW.TEXT_DEFAULTS:
        void refetchTextDefaults().then(() => {
          resourceTabsProviderSetValue(
            organizationTabFilters({
              id,
              selectedTab: LIST_VIEW.TEXT_DEFAULTS
            }).variables as GetTextDefaultsListQueryVariables
          );
        });
        return;
      case LIST_VIEW.UNREGISTERED_CAMPAIGNS:
        void refetchCampaigns().then(() => {
          resourceTabsProviderSetValue(
            organizationTabFilters({
              id,
              selectedTab: LIST_VIEW.UNREGISTERED_CAMPAIGNS
            }).variables as GetCampaignsListQueryVariables
          );
        });
        return;
      case LIST_VIEW.USERS:
        void refetchUsers();
        return;
      default:
        return;
    }
  }, [activeLinkId]);

  return (
    <>
      {activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
      {activeLinkId === LIST_VIEW.TEXT_DEFAULTS && (
        <List
          accessor="textDefaults"
          columns={TextDefaultTableColumns}
          data={textDefaultsData}
          dataFormat={textDefaultTableDataFormatter}
          loading={loadingTextDefaults}
          refetchQuery={handleTextDefaultsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.DOMAINS && (
        <List
          accessor="customDomains"
          columns={DomainTableColumns}
          data={domainsData}
          dataFormat={DomainTableDataFormatter}
          loading={loadingDomains}
          refetchQuery={handleCustomDomainsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.UNREGISTERED_CAMPAIGNS && (
        <List
          accessor="campaigns"
          columns={campaignsTableColumns}
          data={campaignsData}
          dataFormat={campaignTableDataFormatter}
          loading={loadingCampaigns}
          refetchQuery={handleCampaignsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.AUDIT_REPORT && <AuditReportForm />}
      {activeLinkId === LIST_VIEW.BILLING_REPORT && <BillingReportForm />}
      {activeLinkId === LIST_VIEW.LIST_FILTERS && (
        <>
          <List
            accessor="listFilters"
            columns={FiltersTableColumns}
            data={filtersData}
            dataFormat={listFilterTableDataFormatter}
            loading={loadingFilters}
            refetchQuery={refetchFilters}
          />
          <div>{message("Organization")}</div>
        </>
      )}
      {activeLinkId === LIST_VIEW.PROVIDER_COLORS && (
        <List
          accessor="providerColors"
          columns={ProviderColorsTableColumns}
          data={providerColorsData}
          dataFormat={ProviderColorsTableDataFormatter}
          loading={loadingProviderColors}
          refetchQuery={handleProviderColorsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.USERS && (
        <List
          accessor="users"
          columns={UserTableColumns}
          data={usersData}
          dataFormat={userTableDataFormatter}
          loading={loadingUsers}
          refetchQuery={handleUsersRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.PURCHASED_NUMBERS && (
        <List
          accessor="phones"
          columns={campaignPhonesColumns}
          data={data}
          dataFormat={phoneTableDataFormatter}
          loading={loading}
          refetchQuery={refetch}
        />
      )}
      {activeLinkId === LIST_VIEW.TEAMS && (
        <List
          accessor="teams"
          columns={TeamTableColumns}
          data={teamsData}
          dataFormat={teamTableDataFormatter}
          loading={loadingTeams}
          refetchQuery={refetchTeams}
        />
      )}
    </>
  );
};

export default OrganizationShow;
