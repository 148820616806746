// react modules
import React from "react";

// third-party modules
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  ClientFieldsFragment,
  ListFilterFieldsFragment,
  useCreateClientMutation,
  useGetClientQuery,
  useNewClientQuery,
  useUpdateClientMutation
} from "../../generated/graphql";
import {
  CLIENTS_PATH,
  CLIENT_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../constants";
import ClientAnchor from "./ClientAnchor";
import ListPage from "../../components/pages/ListPage";
import sections, {
  ClientGeneralFormOmitValues as formOmitValues
} from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import ClientShow from "./ClientShow";
import { ExportButton } from "../../components/Actions/Export";
import {
  formatFilters,
  normalizeFilters,
  ListFilterOptions,
  ListFilterValue
} from "../../components/ListFilter/utils";

const ClientsRoute = new ResourceRoute({
  name: "Client",
  path: CLIENTS_PATH,
  AnchorComponent: ClientAnchor,
  IndexComponent: () => <ListPage variant="Clients" />,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Client",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Agencies",
      path: "clients",
      baseRoute: "Client",
      actions: (
        <ExportButton baseResource={"Client"} variant={VIEW_LINKS.AGENCIES} />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.AGENCIES} />
      )
    }),
    new NestedResourceRoute({
      name: "Projects",
      path: "projects",
      baseRoute: "Client",
      actions: (
        <ExportButton baseResource={"Client"} variant={VIEW_LINKS.PROJECTS} />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.PROJECTS} />
      )
    }),
    new NestedResourceRoute({
      name: "List Filters",
      path: "list-filters",
      baseRoute: "Client",
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.LIST_FILTERS} />
      )
    }),
    new NestedResourceRoute({
      name: "Contact Phones",
      path: "contact_phones",
      baseRoute: "Client",
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.CONTACT_PHONES} />
      )
    }),
    new NestedResourceRoute({
      name: "Opt Outs Texts",
      path: "opt_outs_texts",
      baseRoute: "Client",
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.OPT_OUTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Purchased Numbers",
      path: "purchased_numbers",
      baseRoute: "Client",
      IndexComponent: ({ id }: { id: string }) => (
        <ClientShow id={id} activeLinkId={VIEW_LINKS.PURCHASED_NUMBERS} />
      )
    })
  ],
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    hydrate: [useNewClientQuery, "newClient"],
    // @ts-ignore
    query: [useGetClientQuery, "client"],
    // @ts-ignore
    update: [useUpdateClientMutation, "updateClient"],
    // @ts-ignore
    create: [useCreateClientMutation, "createClient"],
    // @ts-ignore
    useInitialValues: (client: ClientFieldsFragment) => {
      // maybe get client.phones and set then to both client and purchased tables
      const initialListFilters = client?.listFilters || [];
      const listFilters = formatFilters(initialListFilters);
      const clientFilters = [
        listFilters.find(f => f.type === "Other") as ListFilterOptions
      ];
      const listFiltersAttributes = clientFilters;

      const initialValues = {
        searchAgencyId: client?.agencyId
          ? {
              label: client.agency?.name,
              value: client.agencyId
            }
          : undefined,
        listFilters: initialListFilters,
        listFiltersAttributes
      };

      return initialValues;
    },
    // @ts-ignore
    normalize: (client: any) => {
      if (!client) return client;

      const { listFilters, listFiltersAttributes } = client;

      const filters: ListFilterValue[] = listFiltersAttributes;
      const previousFilters: ListFilterFieldsFragment[] = listFilters;
      client.listFilters = normalizeFilters(filters, previousFilters);

      Object.keys(client as Object)
        .filter(k => k.includes("campaignId-"))
        .forEach(k => delete client[k]);

      const newAttr = { ...client, agencyId: client.searchAgencyId };

      return {
        ..._.omit(newAttr, formOmitValues)
      };
    }
  }
});

export { ClientsRoute };
