// react modules
import React, { useMemo } from "react";

// third-party modules
import {
  CellInputType,
  DataGrid,
  HiddenField,
  useSetFieldValue,
  useGetSubmitForm,
  SearchField,
  useGetValue,
  useWatchContext,
  Fieldset
} from "@onehq/anton";
import { CellContext } from "@tanstack/react-table";
import { NoPaddingButton } from "../../clients/sections/index.styles";

interface BrandConfigurationsFormProps {
  providerId?: string;
  allProviderEnvKeyOptions?: any[];
}

const BrandConfigurationsForm = (props: BrandConfigurationsFormProps) => {
  // hooks
  const getValue = useGetValue();
  const setValue = useSetFieldValue();
  const submitForm = useGetSubmitForm();
  const brandConfigurations = useWatchContext("brandConfigurations");

  const providerName = useMemo(() => {
    return props.providerId?.replace(/([a-z])([A-Z])/g, "$1 $2");
  }, [props.providerId]);

  const providerEnvKeyOptions = useMemo(() => {
    // array of envKeys that were already used
    const usedEnvKeys = (brandConfigurations || [])
      .filter(bc => !!bc.envKey)
      .map(bc => bc.envKey);
    return (
      props.allProviderEnvKeyOptions
        ?.filter(
          option =>
            option?.groupId === `ProviderEnvKey::::${providerName}` &&
            !usedEnvKeys.find(envKey => envKey === option.name)
        )
        ?.map(option => ({ label: option.name, value: option.name })) || []
    );
  }, [props.allProviderEnvKeyOptions, providerName, brandConfigurations]);

  const ActionCustomCell = useMemo(
    () =>
      ({ row }: CellContext<any, any>) => {
        const idName = `brandConfigurationsAttributes.${row.index}.id`;
        const destroyName = `brandConfigurationsAttributes.${row.index}.destroy`;

        // get id value to know if this cell is a new brand config or a existent one
        const isEdit = !!getValue(idName);
        const isDeleting = !!getValue(destroyName);

        // toggle brand config attr destroy, and submits form since "setValue" doesnt trigger autosave
        // if creation row is half is not completed, it doesnt delete this row right away since
        // we want to let the user finishing the creation of the new row of cancel it (blank the new row fields)
        const toggleDestroyBrandConfig = () => {
          setValue(destroyName, !isDeleting);
          !!submitForm && submitForm();
        };
        return (
          <>
            {/* This is to keep id value into the form values */}
            <HiddenField name={idName} />
            {/* This is to keep destroy value into the form values */}
            <HiddenField name={destroyName} />
            {/* if this brand config exists, show delete brand config action */}
            {isEdit && (
              <NoPaddingButton
                variant="inline"
                onClick={toggleDestroyBrandConfig}
              >
                {isDeleting ? "Ready to delete" : "Delete"}
              </NoPaddingButton>
            )}
          </>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const SearchEnvKeyFieldCell = useMemo(
    () =>
      ({ row }: CellContext<any, any>) => {
        // current row is edit or new brand config
        const isEdit = !!getValue(`id-${row.index}`);

        return (
          // @ts-ignore
          <SearchField
            name={`brandConfigurationsAttributes.${row.index}.envKey`}
            isTableInput
            collapseLabel
            placeholder="Select an environment key"
            // filter just by text (removes )
            loadOptions={(text: string) =>
              Promise.resolve(
                providerEnvKeyOptions.filter(
                  o =>
                    o.label
                      .toLowerCase()
                      .replace(/[^0-9a-z]/gi, "")
                      .search(text.toLowerCase()) >= 0
                )
              )
            }
            disabled={isEdit}
            defaultOptions={providerEnvKeyOptions}
          />
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providerEnvKeyOptions]
  );

  const brandCofigurationTableColumns = [
    {
      id: "envKey",
      accessorKey: "envKey",
      header: "Environment Key",
      cell: SearchEnvKeyFieldCell
    },
    {
      id: "envValue",
      accessorKey: "envValue",
      header: "Environment Value",
      inputType: "textField" as CellInputType,
      enableSorting: false,
      maxSize: 1
    },
    {
      id: "actions",
      accessorKey: "actions",
      header: "Actions",
      cell: ActionCustomCell
    }
  ];

  return (
    <Fieldset legend="Configurations">
      <DataGrid
        name="brandConfigurationsAttributes"
        columns={brandCofigurationTableColumns}
        addRows
        customStyle="padding: 8px"
      />
    </Fieldset>
  );
};

export default BrandConfigurationsForm;
