import React, { useEffect } from "react";

import { LinkId } from "@onehq/anton";

import { BRAND_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW } from "../../../constants/brandConstants";
import DataOverview from "../../../components/pages/DataOverview";
import { List } from "@onehq/framework";
import {
  CampaignQueryFilterFields,
  FilterOperation,
  useGetCampaignsListLazyQuery
} from "../../../generated/graphql";
import { PAGE_SIZE } from "../../../utils";
import {
  CampaignTableColumns,
  campaignTableDataFormatter
} from "../../../components/Campaign/CampaignTable";

interface BrandShowProps {
  id: string;
  activeLinkId: LinkId;
}

const Brand = ({ id, activeLinkId }: BrandShowProps) => {
  const [
    ,
    {
      data: campaignsData,
      loading: loadingCampaigns,
      refetch: refetchCampaigns
    }
  ] = useGetCampaignsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: CampaignQueryFilterFields.BrandId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  useEffect(() => {
    switch (activeLinkId) {
      case LIST_VIEW.CAMPAIGNS:
        void refetchCampaigns();
        return;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLinkId]);

  return (
    <>
      {activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
      {activeLinkId === LIST_VIEW.CAMPAIGNS && (
        <List
          accessor="campaigns"
          columns={CampaignTableColumns}
          data={campaignsData}
          dataFormat={campaignTableDataFormatter}
          loading={loadingCampaigns}
          refetchQuery={refetchCampaigns}
        />
      )}
    </>
  );
};

export default Brand;
