/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useMemo } from "react";

// third-party modules
import {
  CellContext,
  HeaderContext,
  RowSelectionState
} from "@tanstack/react-table";
import styled from "styled-components";
import { Flex, theme } from "@onehq/style";
import {
  Action,
  CheckboxInput,
  CellInputType,
  DataGrid,
  Icon,
  SearchField,
  Tooltip,
  useGetSubmitForm,
  useGetValue,
  useSetFieldValue
} from "@onehq/anton";

// app modules
import {
  Campaign,
  CampaignType,
  FilterOperation,
  PhoneQueryFilterFields,
  PhoneStatus,
  ProjectAreaCodesQuery,
  useGetCampaignsListLazyQuery,
  useGetPhonesListLazyQuery,
  useSearchAndBuyNumberMutation
} from "../../../generated/graphql";
import {
  PAGE_SIZE,
  formatCampaignList,
  formatPhoneOption
} from "../../../utils";
import { Phone } from "./ProjectCampaignsForm";
import { ADD, useDispatchGrowlContext } from "@onehq/framework";
import CampaignField from "../../../components/Inputs/CampaignField";

const Root = styled.div`
  padding: ${theme.space.spacing8};
  width: 100%;
  z-index: 1;

  /* fix columns too big */
  & table > thead > tr > th .actionDropdown > * > * {
    margin: 0;
  }
`;

const CheckboxRoot = styled.div`
  label {
    flex-direction: column;
  }
`;

export interface AreaCodeData {
  areaCode: string;
  timesUsed: number;
  campaignId?: string;
  cursor?: string;
  phoneData?: string | { id: string; campaignId: string };
  checkColumn: any;
}

interface ProjectPhonesFieldsProps {
  campaignType: CampaignType;
  refetchQuery: Function;
  clientId?: any;
  lastCursor?: string;
  setRetrievedAreaCodes: React.Dispatch<
    React.SetStateAction<
      NonNullable<ProjectAreaCodesQuery["projectAreaCodes"]["edges"]>
    >
  >;
  isCompleted?: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchText: React.Dispatch<React.SetStateAction<string | null>>;
  setAddToInputName: React.Dispatch<React.SetStateAction<string | null>>;
  prefix: string;
  allPhones: Phone[];
  allCampaigns: Campaign[];
  pendingPhones: any[];
}

const RightAlignedSearchField = styled(SearchField)`
  text-align: right;
`;

const ProjectPhonesFields = ({
  clientId,
  lastCursor,
  campaignType,
  isCompleted,
  setShowDialog,
  setSearchText,
  setAddToInputName,
  prefix,
  refetchQuery,
  setRetrievedAreaCodes,
  allPhones,
  allCampaigns,
  pendingPhones
}: ProjectPhonesFieldsProps) => {
  const [selectedRows, setSelectedRows] = React.useState({});

  const [getCampaigns] = useGetCampaignsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [getPhones] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [buyNumberQuery] = useSearchAndBuyNumberMutation();

  // hooks
  const submitForm = useGetSubmitForm();
  const setValue = useSetFieldValue();
  const getValue = useGetValue();

  const alert = useDispatchGrowlContext();

  // buy the number and create the pc & pp
  // we manually set the created pc & pp to the table
  const buyNumber = useMemo(() => {
    return (areaCode: string, campaignId: string) => {
      if (areaCode && campaignId) {
        buyNumberQuery({ variables: { areaCode, campaignId } })
          .then(response => {
            const errors = response.data?.searchAndBuyNumber?.errors;
            if (errors) {
              alert({
                type: ADD,
                payload: {
                  title: "Error buying number",
                  message: errors,
                  variant: "error"
                }
              });
            } else {
              alert({
                type: ADD,
                payload: {
                  title: "All changes saved",
                  message: response.data?.searchAndBuyNumber?.message,
                  variant: "success"
                }
              });
            }
          })
          .catch(error => console.log(error));
      }
    };
  }, [buyNumberQuery, alert]);

  // custom cell for phones because each row can have different phone options
  // based on the campaign in that row
  const PhoneSearchFieldCell = useMemo(() => {
    return ({ column: { id }, row }: CellContext<any, any>) => {
      const isRegistered = campaignType === CampaignType.Registered;
      const campaignIdVal = getValue(`${prefix}.${row.index}.campaignId`);
      const campaignId: string | undefined = campaignIdVal?.value
        ? campaignIdVal.value
        : campaignIdVal;

      const phoneData = row.getValue<any>("phoneData");
      const areaCode = row.getValue<string>("areaCode");
      const phone = allPhones.find(p => p.id === phoneData?.id);
      const mismatch = phone && !phone.number?.startsWith(areaCode);

      const activeOptions = (
        isRegistered
          ? allPhones.filter(p => p.campaignId === campaignId)
          : allPhones
      ).map(phone => {
        const option = formatPhoneOption(phone);
        option.value = { id: phone.id, campaignId: phone.campaignId };
        return option;
      });
      const pendingOptions = (
        isRegistered
          ? pendingPhones.filter(p => p.campaignId === campaignId)
          : pendingPhones.filter(p => !p.campaign?.organizationId)
      ).map(phone => {
        const option = formatPhoneOption(phone) as any;
        option.value = { id: phone.id, campaignId: phone.campaignId };
        option.isDisabled = true;
        return option;
      });
      const defaultOptions = [
        {
          label: "Pending Phones",
          options: pendingOptions
        },
        {
          label: "Active Phones",
          options: activeOptions
        }
      ];
      const loadOptions = async (text: string) => {
        if (!campaignId && campaignType === CampaignType.Registered) {
          return Promise.resolve([]);
        } else {
          return getPhones({
            variables: {
              first: PAGE_SIZE,
              filters: [
                {
                  field: PhoneQueryFilterFields.CampaignId,
                  operation: FilterOperation.In,
                  arrayValues:
                    campaignType === CampaignType.Registered
                      ? [campaignId || ""]
                      : allCampaigns.map(c => c.id)
                },
                {
                  field: PhoneQueryFilterFields.Number,
                  operation: FilterOperation.Like,
                  value: text.replaceAll(/\(|\)|-|\s/g, "")
                },
                {
                  field: PhoneQueryFilterFields.PhoneStatusId,
                  operation: FilterOperation.Equal,
                  value: `PhoneStatus::::${PhoneStatus.Active}`
                }
              ]
            }
          }).then(response => {
            const phones = response.data?.phones?.nodes || [];
            return phones.map(phone => {
              const option = formatPhoneOption(phone);
              option.value = { id: phone?.id, campaignId: phone?.campaignId };
              return option;
            });
          });
        }
      };
      return (
        <>
          {/* @ts-ignore */}
          <RightAlignedSearchField
            name={`${prefix}.${row.index}.${id}`}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            formatOptionLabel={(option, { context }) => {
              return context === "menu" ? (
                option.label
              ) : (
                <Flex>
                  {mismatch && (
                    <Tooltip
                      content="Mismatch Code"
                      placement="top"
                      delay={0}
                      color="neutral"
                    >
                      <div
                        style={{
                          display: "inline-block",
                          margin: 1,
                          marginRight: 8
                        }}
                      >
                        <Icon
                          name="notEqual"
                          color="neutral75"
                          width={12}
                          strokeWidth={2}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {option.label}
                </Flex>
              );
            }}
            onAddNew={
              campaignId && isRegistered
                ? () => buyNumber(areaCode, campaignId)
                : undefined
            }
            addNewLabel="Buy Number"
            placeholder="Search an option"
            collapseLabel
            isTableInput
            disabled={isCompleted}
            isRightAligned
          />
        </>
      );
    };
  }, [
    allPhones,
    allCampaigns,
    pendingPhones,
    campaignType,
    isCompleted,
    getPhones,
    buyNumber
  ]);

  // custom cell for campaigns because we need to get the current selectField name
  // for the floatingForm (setAddToInputName)
  const CampaignSearchFieldCell = useMemo(() => {
    return ({ column: { id }, row }: CellContext<any, any>) => (
      /* @ts-ignore */
      <CampaignField
        name={`${prefix}.${row.index}.${id}`}
        campaigns={allCampaigns}
        loadOptions={text => {
          const filter = allCampaigns.filter(c => c.name?.includes(text));
          return Promise.resolve(formatCampaignList(filter));
        }}
        onAddNew={(val: string) => {
          setSearchText(val);
          setAddToInputName(`${prefix}.${row.index}.${id}`);
          setShowDialog(true);
        }}
        placeholder="Search a campaign"
        collapseLabel
        isTableInput
        // set selected campaign in the table data
        onChange={() => {
          setValue(`${prefix}.${row.index}.phoneData`, null, {
            absolutePath: false
          });
          submitForm && submitForm();
        }}
        disabled={isCompleted}
        disableExpired
      />
    );
  }, [allCampaigns, clientId, getCampaigns]);

  // columns of the table
  const columns = useMemo(() => {
    return [
      {
        accessorKey: "checkColumn",
        id: "Check Column",
        size: 20,
        header: ({ table }: HeaderContext<any, unknown>) => {
          const emptyPhoneNumberRows: RowSelectionState = table.options.data
            .map((d, index) => ({
              ...d,
              index
            }))
            .filter(f => !f.phoneData)
            .reduce((acc, item) => {
              acc[item.index] = true;
              return acc;
            }, {});

          const selectedRowIndex = Object.keys(selectedRows).map(Number);

          const isRegistered = campaignType === CampaignType.Registered;
          const activeNumberActions = allPhones.map(phone => {
            const { id, campaignId, campaign } = phone;
            const option = formatPhoneOption(phone);
            option.value = { id, campaignId };

            return {
              key: id,
              name: option.label,
              onClick: () => {
                selectedRowIndex.forEach(sIndex => {
                  if (isRegistered) {
                    const name = campaign?.name;
                    const campaignOption = { label: name, value: campaignId };
                    setValue(`${prefix}.${sIndex}.campaignId`, campaignOption, {
                      absolutePath: false
                    });
                  }
                  setValue(`${prefix}.${sIndex}.phoneData`, option, {
                    absolutePath: false
                  });
                });
              }
            };
          });

          return (
            <Action
              type="data-grid"
              name=""
              items={[
                {
                  name: "Check All",
                  onClick: () => table.toggleAllRowsSelected(true)
                },
                {
                  name: "Uncheck All",
                  onClick: () => table.toggleAllRowsSelected(false)
                },

                {
                  name: "Check Unassigned",
                  onClick: () => table.setRowSelection(emptyPhoneNumberRows)
                },
                {
                  name: "Assign number",
                  actionItems: {
                    title: "Active Numbers",
                    // ActionItemProps allows name to be just rendered, we should change ActionItemProps.name to JSX.Element instead of string
                    // @ts-ignore
                    items: activeNumberActions
                  }
                },

                {
                  name: "Clear phones",
                  onClick: () => {
                    setValue("destroyProjectPhones", true)
                    if (submitForm) submitForm();
                  }
                }
              ]}
            />
          );
        },
        cell: ({ row }: CellContext<any, unknown>) => {
          const checked = row.getIsSelected();
          const isIndeterminate = !checked && row.getIsSomeSelected();
          const value = isIndeterminate || checked;
          return (
            <CheckboxRoot>
              <CheckboxInput
                disabled={isCompleted || !row.getCanSelect()}
                isIndeterminate={isIndeterminate}
                label=""
                name={`cell-${row.index}`}
                size={16}
                // @ts-ignore
                value={!!value}
                onChange={(value: any) => row.toggleSelected(value as boolean)}
              />
            </CheckboxRoot>
          );
        }
      },
      {
        id: "id",
        accessorKey: "id",
        header: "id",
        inputType: "hiddenField" as CellInputType
      },
      {
        id: "areaCode",
        accessorKey: "areaCode",
        header: "Area Code",
        inputType: "textField" as CellInputType,
        maxSize: 1,
        meta: { readOnly: true }
      },
      {
        id: "timesUsed",
        accessorKey: "timesUsed",
        header: "Times Used",
        inputType: "textField" as CellInputType,
        maxSize: 1,
        meta: { readOnly: true }
      },
      ...(campaignType === CampaignType.Registered
        ? [
            {
              id: "campaignId",
              accessorKey: "campaignId",
              header: "Campaign",
              cell: CampaignSearchFieldCell
            }
          ]
        : []),
      {
        id: "phoneData",
        accessorKey: "phoneData",
        header: "Phone Number",
        cell: PhoneSearchFieldCell
      }
    ];
  }, [
    campaignType,
    PhoneSearchFieldCell,
    CampaignSearchFieldCell,
    selectedRows
  ]);

  // infinite scroll settings
  const fetchNextPage = async () => {
    await refetchQuery({
      after: lastCursor // Provide the cursor of the last item in the current data set
    }).then((newData: any) => {
      setRetrievedAreaCodes(prevData => [
        ...prevData,
        ...(newData?.data?.projectAreaCodes.edges || [])
      ]);
    });
  };

  return (
    <>
      <Root>
        <DataGrid
          name={prefix}
          columns={columns}
          fixedHeader
          loaderCount={20}
          fetchNextPage={fetchNextPage}
          customStyle="max-height: 600px; overflow-y: auto; "
          disableSort
          rowSelection
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          withRowSelectionColumn={false}
        />
      </Root>
    </>
  );
};

export default ProjectPhonesFields;
